/* Slider Container */
.slider-container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

/* Slider Title */
.slider-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Swiper Container */
.mySwiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* Swiper Slide */
.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 10px;
  padding: 50px;
 
  
}

/* Slide Title */
.slide-title {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

/* Remove shadow effects */
.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}


