@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html{
    scroll-behavior: smooth;
}

.App{
    display: flex;
    /* background-color: white; */
    flex-direction: column;
    position: relative;
    bottom: 0%;
    top: 0%;
    left: 50%;
    transform: translate(-50%,-0%);
    max-width: 1300px;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.548);

}


.pageLoader {
    width: 45px;
    height: 40px;
    background: linear-gradient(#0000 calc(1*100%/6),#fff 0 calc(3*100%/6),#0000 0),
              linear-gradient(#0000 calc(2*100%/6),#fff 0 calc(4*100%/6),#0000 0),
              linear-gradient(#0000 calc(3*100%/6),#fff 0 calc(5*100%/6),#0000 0);
    background-size: 10px 400%;
    background-repeat: no-repeat;
    animation: matrix 1s infinite linear;
  }
  
  @keyframes matrix {
    0% {
      background-position: 0% 100%, 50% 100%, 100% 100%
    }
  
    100% {
      background-position: 0% 0%, 50% 0%, 100% 0%
    }
  }


  .custom-scrollbar {
    overflow: scroll;
    border: 1px solid #d1d5db; /* gray-300 */
    border-radius: 0.375rem; /* rounded-r-md */
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 0.3rem; /* w-2 */
    height: 0.3rem; /* w-2 */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent; /* bg-transparent */
    border-radius: 9999px; /* rounded-full */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #d1d5db; /* gray-300 */
    border-radius: 9999px; /* rounded-full */
  }
  
  .dark .custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent; /* bg-transparent */
  }
  
  .dark .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #a3a3a3; /* neutral-300 */
  }
      

